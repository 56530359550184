body {
  font-family: "Roboto", Arial, Helvetica, sans-serif;
  background-color: #121212;
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 75vh;
}

iframe {
  width: 336px;
  height: 189px;
}

p {
  white-space: pre-wrap;
}

.page-content {
  padding: 2vh 0 10vh;
  min-height: 98vh;
  background-image: url("../../images/axe.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
}

.heading-container {
  text-decoration: none;
  color: white;
  text-align: center;
}

.heading {
  margin-bottom: 12px;
  font-size: 64px;
  margin-top: 120px;
}

.sub-heading {
  margin-top: 8px;
}

.nav-container {
  margin-top: 38px;
  display: flex;
  flex: row;
  justify-content: space-between;
  align-items: center;
  gap: 72px;
  font-size: 20px;
  font-weight: 100;
  height: 230px;
}

.nav-container a {
  text-decoration: none;
  color: white;
  transition: all 0.2s ease-in-out;
}

.nav-container a:hover {
  font-size: 23px;
  color: rgb(233, 227, 255);
}

.section-heading {
  margin-top: 200px;
}

.content-container {
  margin-top: 20px;
}

.playlist-description {
  font-size: 10px;
  color: #cccccc;
  line-break: anywhere;
  word-break: normal;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Interstate, Lucida Grande, Lucida Sans Unicode, Verdana, Tahoma,
    sans-serif;
  font-weight: 100;
}

.playlist-description a {
  color: #cccccc;
  text-decoration: "none";
}

.about-container {
  margin-top: 20px;
  padding: 20px 20px;
  width: 960px;
  text-align: justify;
  background-color: rgba(0, 0, 0, 0.572);
}

.email-container {
  margin-top: 20px;
  margin-bottom: 124px;
}

.email-container a {
  font-size: 16px;
  text-decoration: none;
  color: white;
  transition: all 0.2s ease-in-out;
}

.email-container a:hover {
  font-size: 16px;
  color: rgb(233, 227, 255);
}

@media screen and (max-width: 1070px) {
  .about-container,
  .nav-container {
    width: 336px;
    gap: 12px;
    font-size: 16px;
    margin-top: 12px;
  }
}

@media screen and (min-width: 960px) {
  .heading {
    margin-top: 180px;
  }
  .sub-heading {
    font-size: 24px;
  }
}

@media screen and (max-width: 960px) {
  .sub-heading {
    font-size: 20px;
    margin-top: 20px;
  }
}

@media (min-width: 960px) {
  iframe {
    width: 960px;
    height: 540px;
  }
}
