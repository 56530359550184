.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.icons a {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 40px;
  color: white;
  transition: all 0.2s ease-in-out;
}

.icons a:hover {
  color: rgb(233, 227, 255);
  font-size: 43px;
}
